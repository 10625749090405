import { Screen } from "./Screen"
import { useTheme } from "../Themes/themeManager"
import { useSelector } from 'react-redux';
import { Error } from "./Error";

export const ScreenManager = (props) => {
  const ScreenReducer = useSelector(state => state.ScreenReducer);

  const theme = useTheme()
  const style = {
    manager: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.colors.background,
      color: theme.colors.text,
    }
  }
  style.loading = {
    ...style.manager,
    display: 'Flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  style.error = {
    ...style.loading,
    flexDirection: 'column',
    backgroundColor: theme.colors.error,
  }

  if (ScreenReducer.error) {
    return (<Error />)
  }

  if (ScreenReducer.planif === null) {
    return (<div className='ScreenManager' style={style.loading}>Chargement...</div>)
  }

  return (<div className="ScreenManager" style={style.manager}>
    <Screen identifier={0} position={ScreenReducer.positions[0]} />
    <Screen identifier={1} position={ScreenReducer.positions[1]} />
    <Screen identifier={2} position={ScreenReducer.positions[2]} />
  </div>)
}
import { People } from "../Lottie/People"
import { Animation } from "react-animate-style"

export const PeoplesWidget = (props) => {
  const style = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '80vh',
      flexWrap: 'wrap',
    },
    item: {
      width: '50%',
      minWidth: '50%',
      maxHeight: '40vh',
    },
    title: {
      width: '100%',
      fontSize: 48,
      textAlign: 'center',
      padding: 32,
    }
  }

  const visible = props.screen.position === 'current'
  return <div className="peoples-widget">
    {props.titre && <Animation animationIn="fadeInDown" animationOut="bounceOutUp" isVisible={visible} style={style.title}><div className="titre" > {props.titre}</div></Animation>}
    <div style={style.container}>
      {props.peoples.map((people, index) => (<People people={people} key={index} style={style.item} isActive={visible} timeout={300 * index} duree={props.duree} />))}
    </div>
  </div>
}
import axios from 'axios'
import httpErrors from './errors'

axios.defaults.baseURL = typeof GLOBAL_API_URL === 'undefined' ? 'https://ortholive.wp/wp-json' : GLOBAL_API_URL /* eslint-disable-line */
axios.defaults.headers['Content-Type'] = 'application/json'
axios.defaults.headers['Origin'] = document.location.href
axios.defaults.headers['X-Front-Url'] = document.location.href
axios.defaults.validateStatus = status => true
axios.interceptors.response.use((response) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  else return Promise.reject({
    status: response.status,
    message: httpErrors[response.status],
    navigatorMessage: response.statusText,
    serverMessage: response.data.message,
    response,
  })
}, (error) => {
  return Promise.reject({
    status: error.status,
    message: httpErrors[error.status] || 'Une erreur inconnue est survenue',
    navigatorMessage: error.statusText,
    serverMessage: error.data?.message || 'Une erreur inconnue est survenue',
    error,
  })
})



export default axios
const theme = {
  name: "default",
  description: "Default theme",
  space: (number) => number * 8,
  colors: {
    primary: "#00bcd4",
    secondary: "#ff9800",
    background: "#000000",
    text: "#ffffff",
    textSecondary: "#ffffff",
    textTertiary: "#ffffff",
    error: "#ef5350",
  }
}

export default theme
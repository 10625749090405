import Youtube from 'react-youtube'
import { useDispatch, useSelector } from 'react-redux'
import { stopRotation, rotate } from '../Redux/Screen/actions'

export const YoutubeWidget = (props) => {

  const dispatch = useDispatch()
  const state = useSelector(state => state.ScreenReducer)

  if (state.planif[state.widgets.current].acf_fc_layout === 'youtube') {
    stopRotation();
    return (<Youtube videoId={props.yt_id} onEnd={() => { console.log("Video end"); dispatch(rotate()) }} opts={{ width: '100%', height: '100%', playerVars: { autoplay: 1, mute: 1, controls: 0, infos: 0, iv_load_policy: 3, modestbranding: 1, rel: 0, showinfo: 0 } }} containerClassName='fullHeight' onReady={e => { console.log("ready !", e); e.target.playVideo() }} />)
  }
  return null
}
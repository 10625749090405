import { Animation } from "react-animate-style";

export const MessageWidget = (props) => {

  const directions = {
    top: 'column',
    bottom: 'column-reverse',
    length: 'row',
    right: 'row-reverse',
  }

  const pictoAnimationIn = { top: 'fadeInDown', bottom: 'fadeInUp', right: 'fadeInRight', left: 'fadeInLeft' }
  const pictoAnimationOut = { top: 'bounceOutUp', bottom: 'bounceOutDown', right: 'bounceOutRight', left: 'bounceOutLeft' }
  const messageAnimationIn = { top: 'fadeInUp', bottom: 'fadeInDown', right: 'fadeInLeft', left: 'fadeInRight' }
  const messageAnimationOut = { top: 'bounceOutDown', bottom: 'bounceOutUp', right: 'bounceOutLeft', left: 'bounceOutRight' }

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: directions[props['picto-position']],
  }

  const visible = props.screen.position === 'current'

  return (<div className="message-widget widget" style={style}>
    {props.picto && <Animation animationIn={pictoAnimationIn[props['picto-position']]} animationOut={pictoAnimationOut[props['picto-position']]} isVisible={visible} ><div className="picto"><img src={props.picto.sizes.large} alt={props.picto.alt} /></div></Animation>}
    <Animation animationIn={messageAnimationIn[props['picto-position']]} animationOut={messageAnimationOut[props['picto-position']]} isVisible={visible} ><div className="message" style={{ margin: 16, fontSize: 32 }}> {props.message}</div></Animation>
  </div >)
}
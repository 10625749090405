import { Animation } from "react-animate-style";

export const MeteoInternationaleWidget = (props) => {
  const style = {
    slide: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    topMeteo: {
      minWidth: '80vw',
    },
    blocmeteo: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      margin: '32px auto',
      fontSize: 64,
    },
    temperature: {
      fontSize: '6rem',
      margin: '-30px 0 0 0',
    },
    descmeteo: {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: '1.5rem',
    }
  }
  

  const visible = props.screen.position === 'current'


  return (<div className="meteointer-widget widget" style={style.slide}>
    {props.titre && <Animation animationIn="fadeInDown" animationOut="bounceOutUp" isVisible={visible}><div className="titre" style={style.title}> Météo Internationale</div></Animation>}
      <div className="topMeteo astroFade" style={style.topMeteo}>
        <div style={style.blocmeteo}>
          <h2 style={style.title}>{props.meteo_ville_1}</h2>
          <h1 style={style.temperature}>{Math.round(props.meteo_v1_body.main.temp)}°C</h1>
          <p style={style.descmeteo}>{props.meteo_v1_body.weather[0].description}</p>
        </div>
        <div style={style.blocmeteo}>
          <h2 style={style.title}>{props.meteo_ville_2}</h2>
          <h1 style={style.temperature}>{Math.round(props.meteo_v2_body.main.temp)}°C</h1>
          <p style={style.descmeteo}>{props.meteo_v2_body.weather[0].description}</p>
        </div>
        <div style={style.blocmeteo}>
          <h2 style={style.title}>{props.meteo_ville_3}</h2>
          <h1 style={style.temperature}>{Math.round(props.meteo_v3_body.main.temp)}°C</h1>
          <p style={style.descmeteo}>{props.meteo_v3_body.weather[0].description}</p>
        </div>
      </div>
  </div> )
  
}
/**
 * Return the best text color for a given background color.
 * @param {string} backgroundColor : background color in hex format
 * @return {string} : best text color in hex format
 */
export const contrastedColor = (backgroundColor) => {
  backgroundColor = backgroundColor.replace("#", "");
  var r = parseInt(backgroundColor.substring(0, 2), 16);
  var g = parseInt(backgroundColor.substring(2, 2), 16);
  var b = parseInt(backgroundColor.substring(4, 2), 16);
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? '#000000' : '#FFFFFF';
}